<template>
  <div class="listBox">
    <div class="text">
      <span class="Number">{{ number }}</span>
      <span class="right">{{ $t(title) }}</span>
    </div>
    <div class="imgBox">
      <van-image lazy-load width="100%" height="100%" :src="imgSrc" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanynetworkMListitem",

  data() {
    return {};
  },
  props: {
    number: {
      type: String,
    },
    title: {
      type: String,
      default: "----",
    },
    imgSrc: {
      type: String,
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.listBox {
  .text {
    text-align: center;

    .Number {
      font-size: 36px;
      font-family: Arial;
      font-weight: bold;
      color: #2e82ff;
      margin-right: 8px;
    }
    .right {
      font-size: 23px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .imgBox {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 56px;
    width: 690px;
    height: 390px;
    background: #e9e9e9;
  }
}
</style>