<template>
  <div class="hello">
    <div class="img-container">
      <img style="width: 100%" :src="imgSrc" ref="image" alt="" />
    </div>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HelloWorld",
  data() {
    return {
      myCropper: null,
    };
  },
  props: {
    imgSrc: {
      type: String,
    },
    imgData: {
      type: Object,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
  methods: {
    init() {
      this.myCropper = new Cropper(this.$refs.image, {
        viewMode: 1,
        // dragMode: "none",
        // initialAspectRatio: 1,
        // aspectRatio: 16 / 9,
        // preview: ".before",
        background: false,
        // autoCropArea: 0.6,
        // zoomOnWheel: false,
        crop(event) {
          // console.log(event.detail.x);
          // console.log(event.detail.y);
          // this.top = event.detail.y;
          // this.left = event.detail.x;
          // this.width = event.detail.width;
          // this.height = event.detail.height;
          // console.log(event.detail.width);
          // console.log(event.detail.height);
          // console.log(event.detail.rotate);
          // console.log(event.detail.scaleX);
          // console.log(event.detail.scaleY);
        },
      });
    },
    sureSava() {
      let data = this.myCropper.getData();
      this.imgData.height = data.height;
      this.imgData.width = data.width;
      this.imgData.x = data.x;
      this.imgData.y = data.y;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped >
.hello {
  margin: 0 auto;
  width: 400px;
  height: 400px;
  background-color: #fff;
  .img-container {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>