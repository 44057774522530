<template>
  <div class="contact">
    <van-sticky :offset-top="0">
      <TopMenuText title="contact.contact_us"></TopMenuText>
    </van-sticky>
    <div class="imgBox">
      <van-image width="100%" height="100%" :src="contactTop" />
    </div>
    <div class="info">
      <van-row type="flex" justify="space-between">
        <van-col span="12" data-aos="fade-down-right">
          <div class="item">
            <div class="img">
              <img src="@/assets/images/contact/phone.png" alt="" />
            </div>
            <div class="right">
              <div class="rightTop">{{ $t("contact.contact_number") }}</div>
              <div class="rightBottom">0573-84562222</div>
              <div class="rightBottom">+86-19817195807</div>
            </div>
          </div>
        </van-col>
        <van-col span="12" data-aos="fade-down-left">
          <div class="item">
            <div class="img">
              <img src="@/assets/images/contact/mailbox.png" alt="" />
            </div>
            <div class="right">
              <div class="rightTop">{{ $t("contact.mail_box") }}</div>
              <div class="rightBottom">ling@td-button.com.cn</div>
            </div>
          </div>
        </van-col>
        <van-col span="12" data-aos="fade-up-right">
          <div class="item">
            <div class="img">
              <img src="@/assets/images/contact/factory.png" alt="" />
            </div>
            <div class="right">
              <div class="rightTop">{{ $t("contact.Factory_address") }}</div>
              <div class="rightBottom">
                {{ $t("contact.Factory_address1") }}
              </div>
            </div>
          </div>
        </van-col>
        <van-col span="12" data-aos="fade-up-left">
          <div class="item">
            <div class="img">
              <img src="@/assets/images/contact/company.png" alt="" />
            </div>
            <div class="right">
              <div class="rightTop">{{ $t("contact.Company_address") }}</div>
              <div class="rightBottom">
                {{ $t("contact.Company_address1") }}
              </div>
            </div>
          </div>
        </van-col>
      </van-row>
      <div class="weixin" data-aos="fade-up" data-aos-delay="800">
        <div class="left">
          <img src="@/assets/images/contact/weixin.png" alt="" />
        </div>
        <div class="right">
          <div class="rightTop">{{ $t("contact.Wechat") }}</div>
          <div class="rightBottom">tldyewu008</div>
        </div>
        <div class="imgbox" @click="goods">
          <img :src="code" alt="" />
        </div>
      </div>
    </div>
    <!-- 地图组件 -->
    <div class="map">
      <Map> </Map>
    </div>
  </div>
</template>

<script>
import TopMenuText from "@/components/topMenuText";
import Map from "./components/map.vue";
import contactTop from "@/assets/images/contact/contactTop.png";
import code from "@/assets/images/contact/code.jpg";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      contactTop,
      code,
      show: false,
      images: [code],
    };
  },
  components: {
    TopMenuText,
    Map,
  },
  methods: {
    goods() {
      ImagePreview({
        images: [code],
        closeable: true,
      });
    },
  },
  created() {},
};
</script>
<style scoped lang='less'>
.contact {
  .imgBox {
    width: 100%;
    height: 152.3px;
    background-color: #eee;
    margin-bottom: 52px;
  }
  .info {
    width: 690px;
    margin: 0 auto;
    // height: 300px;
    .van-col {
      width: 330px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(209, 209, 209, 0.35);
      border-radius: 10px;
      margin-bottom: 30px;
    }
    .item {
      // height: 142px;
      // min-height: 142px;
      height: 190px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(209, 209, 209, 0.35);
      border-radius: 10px;
      display: flex;
      justify-content: start;
      align-items: center;

      .img {
        width: 54px;
        height: 54px;
        margin-right: 16px;
        margin-left: 23px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        flex: 1;
        margin-right: 19px;
        .rightTop {
          font-size: 23px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        .rightBottom {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
          margin-bottom: 10px;
        }
      }
    }
    .weixin {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 690px;
      height: 142px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(209, 209, 209, 0.35);
      border-radius: 10px;
      .left {
        width: 54px;
        height: 54px;
        margin-right: 14px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        flex: "1";
        .rightTop {
          font-size: 23px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        .rightBottom {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
      }
      .imgbox {
        width: 74px;
        height: 74px;
        margin-left: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .map {
    margin: 0 auto;
    margin-top: 52px;
    width: 689px;
    height: 352px;
  }
}
</style>