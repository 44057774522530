<template>
  <div class="condition">
    <van-dropdown-menu class="menuBox" active-color="#1989fa">
      <van-dropdown-item class="menuBoxItem" ref="saixuan">
        <template #title>
          <span>{{ $t("product.screen") }}</span>
          <van-icon name="filter-o" />
        </template>
        <van-collapse v-model="formData.activeName" class="zhankai">
          <van-collapse-item :title="this.$t('product.category')" name="1">
            <van-row gutter="5" v-if="get_cate1Data.length > 0">
              <van-col
                span="6"
                v-for="(item, index) in get_cate1Data"
                :key="index"
              >
                <div
                  class="itemli"
                  :class="{ active: item.active }"
                  @click="BtnItem(item, '品类')"
                >
                  {{ language == "zh" ? item.title : item.english || "--" }}
                </div>
              </van-col>
            </van-row>
            <div v-else>{{ $t("product.No_data") }}</div>
          </van-collapse-item>
          <van-collapse-item
            :title="this.$t('product.material_quality')"
            name="2"
          >
            <van-row gutter="5" v-if="get_materialData.length > 0">
              <van-col
                span="6"
                v-for="(item, index) in get_materialData"
                :key="index"
              >
                <div
                  class="itemli"
                  :class="{ active: item.active }"
                  @click="BtnItem(item, '材质')"
                >
                  {{ language == "zh" ? item.title : item.english || "--" }}
                </div>
              </van-col>
            </van-row>
            <div v-else>{{ $t("product.category") }}</div>
          </van-collapse-item>
          <!-- <van-collapse-item :title="$t('product.function')" name="3">
            <van-row gutter="5" v-if="get_special_cateData.length > 0">
              <van-col
                span="6"
                v-for="(item, index) in get_special_cateData"
                :key="index"
              >
                <div
                  class="itemli"
                  :class="{ active: item.active }"
                  @click="BtnItem(item, '功能')"
                >
                  {{ language == "zh" ? item.title : item.english || "--" }}
                </div>
              </van-col>
            </van-row>
            <div v-else>{{ $t("product.category") }}</div></van-collapse-item
          > -->
        </van-collapse>
        <div class="boxTwo">
          <van-button size="large" @click="cancel">
            {{ $t("product.resetting") }}
          </van-button>
          <van-button
            type="danger"
            color="#2E82FF"
            size="large"
            @click="onConfirm"
          >
            {{ $t("product.make_sure") }}
          </van-button>
        </div>
      </van-dropdown-item>
      <van-dropdown-item class="menuBoxItem" ref="item">
        <template #title>
          <span>{{ $t("product.Img_search") }} </span>
          <span class="iconfont">&#xe8d7;</span>
        </template>

        <div class="img">
          <van-uploader
            v-if="!fileList"
            multiple
            :max-count="1"
            :after-read="afterRead"
            class="uploader"
            ref="file"
          />
          <van-image
            v-else
            width="100%"
            height="100%"
            fit="contain"
            :src="fileList"
          />
        </div>
        <div class="bottomBtn" v-if="fileList">
          <van-row>
            <van-col span="8">
              <van-button plain round type="info" @click="selectBox">
                {{ $t("product.Re_box_selection") }}</van-button
              >
            </van-col>
            <van-col span="8">
              <van-uploader :max-count="1" :after-read="afterRead">
                <van-button @click="changImg" plain round type="info">
                  {{ $t("product.Change_a") }}</van-button
                >
              </van-uploader>
            </van-col>
            <van-col span="8"
              ><van-button plain round type="info" @click="cancelImg">
                {{ $t("product.Cancel_the_map_search") }}</van-button
              ></van-col
            >
          </van-row>
        </div>
      </van-dropdown-item>
    </van-dropdown-menu>

    <!-- 图片剪切弹窗 -->
    <van-dialog
      @click.stop="
        {
        }
      "
      width="80%"
      v-model="showPopup"
      :title="$t('product.Box_pictures')"
      show-cancel-button
      @confirm="confirm"
      @close="close"
    >
      <ImgCut
        ref="ImgCut"
        v-if="showPopup"
        :imgSrc="ImgUrl"
        :imgData="imgData"
      ></ImgCut>
    </van-dialog>
  </div>
</template>

<script>
import axios from "axios";
import picUploadPositionTld from "@/api/img.js";
import imgUpload from "./imgupload.vue";
import ImgCut from "./imgCut.vue";
import baseUrl from "@/utils/base.js";
import { mapState } from "vuex";
export default {
  name: "CompanynetworkMCondition",
  components: {
    imgUpload,
    ImgCut,
  },

  data() {
    return {
      showPopup: false,
      formData: {
        activeName: ["1"],
      },
      fileList: "", //src路径
      ImgUrl: "",
      imgData: {},
    };
  },
  computed: {
    ...mapState(["language"]),
  },
  props: {
    get_cate1Data: {
      type: Array,
      default: () => [],
    },
    get_materialData: {
      type: Array,
      default: () => [],
    },
    get_special_cateData: {
      type: Array,
      default: () => [],
    },
    img_arr: {
      type: String,
    },
  },
  methods: {
    onConfirm() {
      this.$refs.saixuan.toggle();
      // this.$refs.item.toggle();
      this.$emit("btnOk", "确定");
    },
    cancel() {
      this.$refs.saixuan.toggle();
      // this.$refs.item.toggle();
      this.$emit("btnOk", "重置");
    },
    // 修改品类的值
    BtnItem(item, type) {
      this.$emit("BtnItem", item, type);
    },
    // 上传图片
    afterRead(file) {
      // 此时可以自行将文件上传至服务
      const forms = new FormData();
      //这里的file表示给后台传的属性名字，这里看接口文档需要传的的属性
      forms.append("file", file.file); // 获取上传图片信息
      axios
        .post("/index/goods/get_location", forms, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          //如果传入的响应状态码为200，则成功将文件发送给后台
          if (res.data.code === 200) {
            this.BtnItem(res.data, "图搜");
            this.fileList = res.data.data.pic;
            this.imgData = res.data.data;
          } else {
            console.log(res.data.msg); //这块是请求失败后台给返回的相应的数据
          }
        });
    },
    // 取消图搜
    cancelImg() {
      this.BtnItem("", "图搜");
      this.fileList = "";
      this.$refs.item.toggle();
    },
    // 换一张图搜
    changImg() {
      this.$refs.file.$el.click();
    },
    selectBox() {
      this.showPopup = true;
      if (baseUrl == "/") {
        const url = new URL(this.fileList);
        this.ImgUrl = url.pathname;
      } else {
        this.ImgUrl = this.fileList;
      }
    },
    close() {
      this.showPopup = false;
    },
    // 弹窗确认
    confirm() {
      this.$refs.ImgCut.sureSava();
      let item = {
        data: this.imgData,
      };
      this.BtnItem(item, "图搜");
    },
  },
};
</script>

<style lang="less" scoped>
.condition {
  background-color: #fff;
  /deep/ .menuBox {
    .van-dropdown-menu__bar {
      .van-dropdown-menu__item {
        .van-dropdown-menu__title {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          &:after {
            border: none;
          }
        }
      }
    }
    .menuBoxItem {
      .zhankai {
        .itemli {
          width: 163px;
          height: 52px;
          background-color: #f5f5f5;
          border-radius: 26px;
          text-align: center;
          line-height: 52px;
          margin-bottom: 18px;
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #626262;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .active {
          color: #2e82ff;
          background: #f0f5ff;
        }
      }
      .boxTwo {
        display: flex;
      }
      .img {
        box-sizing: bor;
        margin-top: 36px !important;
        margin-bottom: 36px !important;
        margin: 0 auto;
        width: 400px;
        height: 400px;
        border: 1px solid #efefef;
        .uploader {
          width: 100%;
          height: 100%;
          .van-uploader__wrapper {
            width: 100%;
            height: 100%;
            .van-uploader__upload {
              width: 100%;
              height: 100%;
              margin: 0;
            }
            .van-uploader__preview {
              width: 100%;
              height: 100%;
              margin: 0;
              .van-image {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .bottomBtn {
        margin-top: 40px;
        margin-bottom: 45px;
        padding: 0 46px;
        .van-button--round {
          width: 198px;
          height: 70px;
        }
      }
    }
  }
}
</style>