<template>
  <div class="productBox">
    <van-sticky :offset-top="0">
      <TopMenuText title="product.product"></TopMenuText>
    </van-sticky>
    <div>
      <Condition
        :get_cate1Data="get_cate1Data"
        :get_materialData="get_materialData"
        :get_special_cateData="get_special_cateData"
        :img_arr="formData.img_arr"
        @BtnItem="BtnItem"
        @btnOk="btnOk"
      ></Condition>
    </div>
    <!-- 列表页面 -->
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-row type="flex" justify="space-between">
          <van-col
            span="12"
            v-for="(item, index) in goods_listData"
            :key="index"
            @click="goDetai(item.uid)"
          >
            <ListItem
              v-if="true"
              :imgSrc="item.thumb_display"
              :title="
                language == 'zh' ? item.title : item.title_english || '--'
              "
              :uid="item.uid"
            ></ListItem
          ></van-col>
        </van-row>
      </van-list>
    </div>
  </div>
</template>

<script>
import Condition from "./components/condition.vue";
import ListItem from "./components/listItem.vue";
import TopMenuText from "@/components/topMenuText";
import { mapState } from "vuex";
import {
  goods_list,
  get_cate1,
  get_material,
  get_special_cate,
} from "@/utils/http.js";

export default {
  components: {
    Condition,
    ListItem,
    TopMenuText,
  },
  data() {
    return {
      loading: false, // 控制列表下拉加载更多
      finished: false, // 如果为true 就停止请求
      formData: {
        cate_id: "", //品类
        material_id: "", // 材质
        special_cate: "", // 功能
        img_arr: "",
      }, // 总的筛选条件
      goods_listData: [], // 列表数据
      page: 1,
      page_number: 10,
      get_cate1Data: [], // 品类的数据
      get_materialData: [], //  材质数据
      get_special_cateData: [], // 功能的数据
    };
  },
  computed: {
    ...mapState(["language"]),
  },
  activated() {},

  mounted() {},
  created() {
    this.get_cate1();
    this.get_material();
    this.get_special_cate();
  },
  methods: {
    show() {},
    // 请求列表的方法
    async goods_list() {
      const res = await goods_list({
        ...this.formData,
        page: this.page,
        page_number: this.page_number,
      });
      this.goods_listData = res.list;
    },
    // 请求品类的方法
    async get_cate1() {
      const res = await get_cate1();
      res.list.forEach((item, index) => {
        item.active = false;
      });
      this.get_cate1Data = res.list;
    },
    // 请求获取材质的方法
    async get_material(data) {
      const res = await get_material(data);
      res.list.forEach((item, index) => {
        item.active = false;
      });
      this.get_materialData = res.list;
    },
    // 获取细分分类功能
    async get_special_cate(data) {
      const res = await get_special_cate(data);
      res.list.forEach((item, index) => {
        item.active = false;
      });
      this.get_special_cateData = res.list;
    },

    async onLoad() {
      // 异步更新数
      const res = await goods_list({
        ...this.formData,
        page: this.page,
        page_number: this.page_number,
      });
      // console.log(res);
      this.loading = false;
      if (res.code == 200) {
        this.goods_listData = [...this.goods_listData, ...res.list];
        this.page++;
        this.finished = false;
      }
      if (this.goods_listData.length >= res.count) {
        this.finished = true;
      }
    },
    // 修改品类的值
    BtnItem(item, type) {
      if (type == "品类") {
        this.formData = {
          cate_id: "", //品类
          material_id: "", // 材质
          special_cate: "", // 功能
        };
        this.publicMethod(this.get_cate1Data, item.id, "cate_id");
        this.get_material({ cate_id: item.id });
        this.get_special_cate({ cate_id: item.id });
      } else if (type == "材质") {
        this.publicMethod(this.get_materialData, item.id, "material_id");
        this.publicMethod(this.get_cate1Data, item.cate_id, "cate_id");
        this.get_special_cate({ cate_id: item.cate_id, material_id: item.id });
      } else if (type == "功能") {
        this.publicMethod(this.get_special_cateData, item.id, "special_cate");
        this.publicMethod(
          this.get_materialData,
          item.material_id,
          "material_id"
        );
        this.publicMethod(this.get_cate1Data, item.cate_id, "cate_id");
      } else if (type == "图搜") {
        if (item && item.data) {
          this.formData.img_arr = JSON.stringify(item.data);
        } else {
          this.formData.img_arr = "";
        }
        this.page = 1;
        this.goods_listData = [];
        this.onLoad();
      }
    },

    // 修改品类材质功能的方法
    publicMethod(data, id, key) {
      this.formData[key] = id;
      data.forEach((element) => {
        if (element.id == id) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    },
    // 子组件点击确定按钮
    btnOk(type) {
      console.log(type);
      if (type == "确定") {
        this.page = 1;
        this.onLoad();
        this.goods_listData = [];
      } else if (type == "重置") {
        this.page = 1;
        this.publicMethod(this.get_cate1Data, "", "cate_id");
        this.publicMethod(this.get_materialData, "", "material_id");
        this.publicMethod(this.get_special_cateData, "", "special_cate");
        this.onLoad();
        this.goods_listData = [];
      }
    },
    // 跳转到详情页面
    goDetai(uid) {
      this.$router.push({
        path: "/product/detail",
        query: { uid: uid },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.productBox {
  background-color: #f7f5f6;
  height: 100vh;
  // padding-top: 85px;
  // .top {
  //   position: fixed;
  //   z-index: 2000;
  //   top: 0;
  //   left: 0;
  //   text-align: center;
  //   width: 750px;
  //   height: 85px;
  //   line-height: 85px;
  //   background: #ffffff;
  //   font-size: 32px;
  //   font-family: Source Han Sans CN;
  //   font-weight: 400;
  //   color: #000000;
  //   border-bottom: 1px solid #f1f1f1;
  //   .icoBox {
  //     position: absolute;
  //     left: 22px;
  //     font-size: 32px;
  //     font-weight: 700;
  //   }
  // }
  /deep/.list {
    width: 750px;
    padding: 0 26px;
    padding-bottom: 14px;
    padding-top: 14px;
    min-height: 86vh;
    // 修改遮罩层覆盖
    background-color: #f7f5f6;
    .van-list {
      width: 100%;
    }
    .van-col {
      width: 342px;
    }
  }
}
</style>