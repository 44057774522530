<template>
  <div class="listItem">
    <div class="listItemTop">
      <img src="@/assets/images/company/fourRound.png" alt="" />
      {{ $t(title) }}
      <img src="@/assets/images/company/fourRound.png" alt="" />
    </div>
    <div class="listItemBottom">
      <van-row>
        <van-col
          span="6"
          v-for="(item, index) in imgArr"
          :key="index"
          @click="showImg(index)"
        >
          <van-image
            lazy-load
            fit="cover"
            width="100%"
            height="100%"
            :src="item"
          />
        </van-col>
      </van-row>
    </div>
    <van-image-preview v-model="show" :images="imgPreArr" @change="onChange">
      <template v-slot:index> {{ $t(title) }} {{ index + 1 }}</template>
    </van-image-preview>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  name: "CompanynetworkMListItem",

  data() {
    return {
      show: false,
      index: 0,
    };
  },
  props: {
    title: {
      type: String,
    },
    imgArr: {
      type: Array,
      default: () => [],
    },
    imgPreArr: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {},

  methods: {
    onChange(index) {
      this.index = index;
    },
    showImg(index) {
      ImagePreview({
        images: this.imgPreArr,
        closeable: true,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.listItem {
  width: 706px;
  margin: 72px auto;
  .listItemTop {
    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
    }
    text-align: center;
  }
  .listItemBottom {
    margin-top: 29px;
    .van-row {
      .van-col {
        width: 220px;
        height: 220px;
        background: #e9e9e9;
        border: 1px solid #eaeaea;
        margin-right: 23px;
        margin-bottom: 20px;
      }
      .van-col:nth-child(3n) {
        margin-right: 0px;
      }
    }
  }
}
</style>