<template>
  <div class="detail">
    <van-sticky :offset-top="0">
      <TopMenuText
        title="product.Garment_Accessories"
        :imgshow="true"
      ></TopMenuText>
    </van-sticky>

    <div class="swipers">
      <van-swipe class="my-swipe">
        <van-swipe-item v-for="(image, index) in imgArr" :key="index">
          <img width="100%" height="100%" fit="contain" v-lazy="image.pic" />
        </van-swipe-item>
      </van-swipe>
      <div class="backBox" @click="goback">
        <img src="@/assets/images/proudct/detailback.png" alt="" />
      </div>
    </div>
    <div class="titleText">{{ title || "--" }}</div>
    <ul class="listBox">
      <li class="listBpxItem" v-for="(item, index) in baseInfo" :key="index">
        <span class="left">
          <span>{{
            language == "zh" ? item.display : item.display || "--"
          }}</span> </span
        >:
        <span class="right">{{ item.value || "--" }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import TopMenuText from "@/components/topMenuText";
import { goods_detail, goods_detail_english } from "@/utils/http.js";
import { mapState } from "vuex";
export default {
  name: "CompanynetworkMDetaile",

  data() {
    return {
      imgArr: [],
      baseInfo: [],
      title: "",
    };
  },
  components: {
    TopMenuText,
  },
  computed: {
    ...mapState(["language"]),
  },
  created() {
    window.scroll(0, 0);
  },
  mounted() {},
  methods: {
    async goods_detail(data) {
      const res = await goods_detail(data);
      this.imgArr = res.img;
      this.baseInfo = res.basis;
      this.publicTitle(res.basis);
    },
    async goods_detail_english(data) {
      const res = await goods_detail_english(data);
      this.imgArr = res.img;
      this.baseInfo = res.basis;
      this.publicTitle(res.basis);
    },
    // 提取title 的标题
    publicTitle(data) {
      data.forEach((item) => {
        if (item.key == "title") {
          this.title = item.value;
        }
      });
    },
    // 返回产品列表页面
    goback() {
      this.$router.push("/product");
    },
  },
  activated() {
    window.scroll(0, 0);
    this.imgArr = [];
    this.baseInfo = [];
    this.title = "";
    if (this.language == "en") {
      this.goods_detail_english({ uid: this.$route.query.uid });
    } else {
      this.goods_detail({ uid: this.$route.query.uid });
    }
  },

  watch: {
    language: {
      handler(newName, oldName) {
        if (newName == "en") {
          this.goods_detail_english({ uid: this.$route.query.uid });
        } else {
          this.goods_detail({ uid: this.$route.query.uid });
        }
      },
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法，如果设置了false，那么效果和上边例子一样
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  .swipers {
    position: relative;
    width: 750px;
    height: 750px;
    /deep/ .my-swipe {
      width: 100%;
      height: 100%;
      /deep/ .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: #39a9ed;
      }
    }
    .backBox {
      position: absolute;
      top: 24px;
      left: 26px;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .titleText {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    height: 95px;
    line-height: 95px;
    margin: 0 31px;
    border-bottom: 1px solid #e6e6e6;
  }
  .listBox {
    margin: 0 30px;
    margin-top: 32px;
    margin-bottom: 100px;
    .listBpxItem {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #626262;
      margin-bottom: 32px;
      .left {
        display: inline-block;
        min-width: 100px;

        span {
          float: right;
        }
      }
    }
  }
}
</style>