<template>
  <div class="listItem">
    <div class="top">
      <van-image
        width="100%"
        height="100%"
        fit="contain"
        lazy-load
        :src="imgSrc"
      />
    </div>
    <div class="bottom">
      <div class="title">{{ uid }}</div>
      <div class="text">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanynetworkMListItem",

  data() {
    return {};
  },
  props: {
    imgSrc: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "",
    },
    uid: {
      type: String,
      default: "--",
    },
    title: {
      type: String,
      default: "--",
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.listItem {
  width: 342px;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 14px;
  .top {
    height: 342px;
  }
  .bottom {
    padding: 19px 16px;
    .title {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
    }
    .text {
      margin-top: 16px;
      font-size: 23px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>