<template>
  <!-- <div class="img"><van-uploader :after-read="afterRead" /></div> -->
  <div class="img">
    <van-uploader
      v-model="fileList"
      multiple
      :max-count="1"
      :after-read="afterRead"
      preview-size="200px"
      class="uploader"
      ref="file"
    />
  </div>
</template>

<script>
import picUploadPositionTld from "@/api/img.js";
import axios from "axios";
export default {
  name: "CompanynetworkMImgupload",

  data() {
    return {
      fileList: [], //默认是一个空数组
      imageData: {},
      isShow: false,
      showList: false,
    };
  },
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);

      //在这块创建FormData对象
      // FormData 对象的使用：
      // 1.用一些键值对来模拟一系列表单控件：即把form中所有表单元素的name与value组装成一个queryString
      // 2. 异步上传二进制文件。
      //上传图片需要转换二进制这里要用到FormData
      const forms = new FormData();
      //这里的file表示给后台传的属性名字，这里看接口文档需要传的的属性
      forms.append("file", file.file); // 获取上传图片信息

      //向后端发送相应的请求
      //这块的url是具体的交互接口
      //headers是上传图片需要用到的响应头，此处的token是后端那边给设置的，所以我请求的时候需要带上token，
      //token根据实际情况自行添加
      axios
        .post("/index/goods/get_location", forms, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          //如果传入的响应状态码为200，则成功将文件发送给后台
          if (res.data.code === 200) {
            // console.log(res.data);
            //this.imageData = res.data.showapi_res_body;
            //this.isShow = false;
            //this.showList = true;
            //Toast(res.data.showapi_res_body.remark);
          } else {
            //Toast(res.data.msg);
            //this.isShow = false;
            console.log(res.data.msg); //这块是请求失败后台给返回的相应的数据
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.van-uploader {
  width: 100%;
  height: 100%;
  .van-uploader__wrapper {
    width: 100%;
    height: 100%;
    .van-uploader__upload {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}
</style>