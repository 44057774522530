<template>
  <div class="company">
    <van-sticky :offset-top="0">
      <TopMenuText title="company.company"></TopMenuText>
    </van-sticky>
    <div class="lineBox"></div>
    <div class="imgBox" @click="showImgTop">
      <van-image width="100%" height="100%" lazy-load :src="company" />
    </div>
    <div
      class="textBox"
      data-aos="fade-down"
      data-aos-offset="200"
      :data-aos-once="true"
    >
      {{ $t("company.text") }}
    </div>
    <div class="topimg">
      <div class="topImgleft" @click="showImg(0)">
        <van-image width="100%" height="100%" :src="big5" />
      </div>
      <div class="topImgRight">
        <div class="topImgRightTop" lazy-load @click="showImg(1)">
          <van-image width="100%" height="100%" :src="big1" />
        </div>
        <div class="topImgRightBottom" lazy-load @click="showImg(2)">
          <van-image width="100%" height="100%" :src="big2" />
        </div>
      </div>
      <div class="topImgRight">
        <div class="topImgRightTop" @click="showImg(3)">
          <van-image width="100%" lazy-load height="100%" :src="big3" />
        </div>
        <div class="topImgRightBottom" @click="showImg(4)">
          <van-image width="100%" height="100%" lazy-load :src="big4" />
        </div>
      </div>
    </div>
    <Listitem
      title="company.Company_glory"
      :imgArr="rongImgArr"
      :imgPreArr="rongImgPreArr"
    ></Listitem>
    <Listitem
      title="company.qualification_certificate"
      :imgArr="zizhiImgArr"
      :imgPreArr="zizhiImgPreArr"
    ></Listitem>
  </div>
</template>

<script>
import TopMenuText from "@/components/topMenuText";
import Listitem from "./components/listItem.vue";
import company from "@/assets/images/company/company.png";
import big1 from "@/assets/images/company/big1.jpeg";
import big2 from "@/assets/images/company/big2.jpeg";
import big3 from "@/assets/images/company/big3.jpeg";
import big4 from "@/assets/images/company/big4.jpeg";
import big5 from "@/assets/images/company/big5.jpg";
import rong1 from "@/assets/images/company/rong1.jpeg";
import rong2 from "@/assets/images/company/rong2.jpeg";
import rong3 from "@/assets/images/company/rong3.jpeg";
import zizhi1 from "@/assets/images/company/zizhi1.jpeg";
import zizhi2 from "@/assets/images/company/zizhi2.jpeg";
import zizhi3 from "@/assets/images/company/zizhi3.jpeg";
import zizhi4 from "@/assets/images/company/zizhi4.jpeg";
import zizhi5 from "@/assets/images/company/zizhi5.jpeg";
import zizhi6 from "@/assets/images/company/zizhi6.jpg";
import zizhi7 from "@/assets/images/company/zizhi7.jpg";
import zizhi8 from "@/assets/images/company/zizhi8.jpg";
import zizhi9 from "@/assets/images/company/zizhi9.jpg";
import { ImagePreview } from "vant";
export default {
  name: "CompanynetworkMCompany",

  data() {
    return {
      company,
      big1,
      big2,
      big3,
      big4,
      big5,
      rongImgArr: [rong1, rong2, rong3],
      rongImgPreArr: [
        "https://m.td-button.com//static_pic/company/rong1.jpeg",
        "https://m.td-button.com//static_pic/company/rong2.jpeg",
        "https://m.td-button.com//static_pic/company/rong3.jpeg",
      ],
      zizhiImgArr: [
        zizhi1,
        zizhi2,
        zizhi3,
        zizhi4,
        zizhi5,
        zizhi6,
        zizhi7,
        zizhi8,
        zizhi9,
      ],
      zizhiImgPreArr: [
        "https://m.td-button.com//static_pic/company/zizhi1.jpeg",
        "https://m.td-button.com//static_pic/company/zizhi2.jpeg",
        "https://m.td-button.com//static_pic/company/zizhi3.jpeg",
        "https://m.td-button.com//static_pic/company/zizhi4.jpeg",
        "https://m.td-button.com//static_pic/company/zizhi5.jpeg",
        "https://m.td-button.com//static_pic/company/zizhi6.jpg",
        "https://m.td-button.com//static_pic/company/zizhi7.jpg",
        "https://m.td-button.com//static_pic/company/zizhi8.jpg",
        "https://m.td-button.com//static_pic/company/zizhi9.jpg",
      ],
    };
  },
  components: {
    TopMenuText,
    Listitem,
  },

  mounted() {},

  methods: {
    showImg(index) {
      ImagePreview({
        images: [
          "https://m.td-button.com//static_pic/company/big5.jpg",
          "https://m.td-button.com//static_pic/company/big1.jpeg",
          "https://m.td-button.com//static_pic/company/big2.jpeg",
          "https://m.td-button.com//static_pic/company/big3.jpeg",
          "https://m.td-button.com//static_pic/company/big4.jpeg",
        ],
        loop: true,
        startPosition: index,
      });
    },
    showImgTop() {
      ImagePreview({
        images: ["https://m.td-button.com//static_pic/company/company.png"],
        startPosition: 0,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  display：table .lineBox {
    height: 1px;
    width: 750px;
    background-color: #f0f0f0;
  }
  .imgBox {
    margin: 0 auto;
    margin-top: 20px;
    width: 670px;
    height: 323px;
    background-color: #e6e6e6;
  }
  .textBox {
    width: 690px;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 23px;
    line-height: 40px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    text-align: justify;
    // // height: 1340px;
    // min-height: 700px;
  }
  .topimg {
    margin: 0 auto;
    margin-top: 36px;
    width: 706px;
    display: flex;
    justify-content: space-between;
    .topImgleft {
      width: 220px;
      height: 303px;
      background: #e9e9e9;
      border: 1px solid #eaeaea;
    }
    .topImgRight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .topImgRightTop,
      .topImgRightBottom {
        width: 220px;
        height: 140px;
        background: #e9e9e9;
        border: 1px solid #eaeaea;
      }
    }
  }
}
</style>