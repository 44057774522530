<template>
  <div class="superiority">
    <van-sticky :offset-top="0">
      <TopMenuText :title="$t('home.superiority')"></TopMenuText>
    </van-sticky>
    <div class="imgBox">
      <van-image lazy-load width="100%" height="100%" :src="superioritytop" />
    </div>
    <div class="company">{{ $t("superiority.enterprise_advantage") }}</div>
    <Listitem
      number="01"
      title="superiority.first"
      :imgSrc="youshi1"
    ></Listitem>
    <Listitem
      number="02"
      title="superiority.second"
      :imgSrc="youshi2"
    ></Listitem>
    <Listitem
      number="03"
      title="superiority.third"
      :imgSrc="youshi3"
    ></Listitem>
    <Listitem
      number="04"
      title="superiority.fourth"
      :imgSrc="youshi4"
    ></Listitem>
    <div class="ourServe">{{ $t("superiority.Our_service") }}</div>
    <div class="outBoxTop">
      <van-row type="flex" justify="space-between">
        <van-col span="8">
          <div class="img">
            <van-image
              lazy-load
              fit="cover"
              width="100%"
              height="100%"
              :src="server1"
            />
          </div>
          <div class="text">{{ $t("superiority.Our_first") }}</div>
        </van-col>
        <van-col span="8">
          <div class="img">
            <van-image lazy-load width="100%" height="100%" :src="server2" />
          </div>
          <div class="text">{{ $t("superiority.Our_second") }}</div>
        </van-col>
        <van-col span="8">
          <div class="img">
            <van-image lazy-load width="100%" height="100%" :src="server3" />
          </div>
          <div class="text">{{ $t("superiority.Our_third") }}</div>
        </van-col>
      </van-row>
    </div>
    <div class="outBoxBottom">
      <van-row type="flex" justify="space-between">
        <van-col span="12">
          <div class="img">
            <van-image lazy-load width="100%" height="100%" :src="server4" />
          </div>
          <div class="text">{{ $t("superiority.Our_fourth") }}</div>
        </van-col>
        <van-col span="12">
          <div class="img">
            <van-image lazy-load width="100%" height="100%" :src="server5" />
          </div>
          <div class="text">{{ $t("superiority.Our_fifth") }}</div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import Listitem from "./listitem.vue";
import TopMenuText from "@/components/topMenuText";
import superioritytop from "@/assets/images/superiority/superioritytop.png";
import youshi1 from "@/assets/images/superiority/youshi1.png";
import youshi2 from "@/assets/images/superiority/youshi2.png";
import youshi3 from "@/assets/images/superiority/youshi3.png";
import youshi4 from "@/assets/images/superiority/youshi4.png";
import server1 from "@/assets/images/superiority/server1.png";
import server2 from "@/assets/images/superiority/server2.png";
import server3 from "@/assets/images/superiority/server3.png";
import server4 from "@/assets/images/superiority/server4.png";
import server5 from "@/assets/images/superiority/server5.png";
export default {
  name: "CompanynetworkMSuperiority",
  components: {
    Listitem,
    TopMenuText,
  },

  data() {
    return {
      superioritytop,
      youshi1,
      youshi2,
      youshi3,
      youshi4,
      server1,
      server2,
      server3,
      server4,
      server5,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.superiority {
  .top {
    width: 750px;
    height: 85px;
    text-align: center;
    line-height: 85px;
    position: relative;
    .size {
      position: absolute;
      left: 36px;
      top: 0;
      font-size: 42px;
    }
    .text {
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
    }
  }
  .imgBox {
    width: 100%;
    height: 152.3px;
    background-color: #eee;
  }
  .company {
    text-align: center;
    margin-top: 31px;
    margin-bottom: 56px;
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
  }
  .ourServe {
    text-align: center;
    padding-top: 16px;
    margin-bottom: 30px;
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
  }
  .outBoxTop,
  .outBoxBottom {
    width: 690px;
    margin: 0 auto;
    text-align: center;
  }
  .outBoxTop {
    .img {
      width: 218px;
      height: 218px;
      background-color: pink;
      margin-bottom: 22px;
    }
    .text {
      font-size: 23px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .outBoxBottom {
    margin-top: 30px;
    padding-bottom: 110px;
    .img {
      width: 336px;
      height: 220px;
      background-color: pink;
      margin-bottom: 22px;
    }
    .text {
      font-size: 23px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>